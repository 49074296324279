import styled from 'styled-components';

import {
  BarChart as BaseBarChart,
  ComposedChart as BaseComposedChart,
  LineChart as BaseLineChart,
  PieChart as BasePieChart,
  ScatterChart as BaseScatterChart,
} from 'recharts';

import * as svars from 'assets/style/variables';

const makeStyledChart = (RechartChartComponent) => styled(
  RechartChartComponent
)`
  &&& {
    font-size: ${svars.chartFontSize};
    path {
      outline: none;
    }
    span.recharts-legend-item-text > span {
      background: transparent;
      color: ${svars.chartFontColor};
      font-weight: ${svars.fontWeightLight};
    }

    text {
      fill: ${svars.chartFontColor};
      user-select: none;

      & tspan {
        fill: ${svars.chartFontColor};
      }
    }
  }
`;

export const ComposedChart = makeStyledChart(BaseComposedChart);
export const LineChart = makeStyledChart(BaseLineChart);
export const ScatterChart = makeStyledChart(BaseScatterChart);
export const PieChart = makeStyledChart(BasePieChart);
export const BarChart = makeStyledChart(BaseBarChart);
