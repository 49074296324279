import { useCallback, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider, List } from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { SmallHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import Segment from 'components/ui/Segment';
import emptyFiltersUrl from 'components/ui/svg/undraw_circles_y7s2.svg';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const N_LIST_ITEM_DISPLAYED = 4;
function ListField({ values, formatter }) {
  const [isToggled, setToggled] = useState(false);
  const doToggle = useCallback(() => !setToggled(!isToggled), [isToggled]);
  let toShow = values;
  let showLink = false;
  const nToToggle = values.length - N_LIST_ITEM_DISPLAYED;
  if (nToToggle > 0) {
    showLink = true;
    if (!isToggled) {
      toShow = values.slice(0, N_LIST_ITEM_DISPLAYED);
    }
  }
  return (
    <List>
      {toShow.map((value) => (
        <List.Item key={`li-${value}`}>{formatter?.(value) || value}</List.Item>
      ))}
      {showLink ? (
        <List.Item key="link">
          <Link onClick={doToggle}>
            {isToggled ? <Trans id="show-less" /> : <Trans id="show-more" />}
            {` (${nToToggle})`}
          </Link>
        </List.Item>
      ) : null}
    </List>
  );
}
ListField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  formatter: PropTypes.func,
};
ListField.defaultProps = { values: [], formatter: null };

function OneFilter({ label, value, formatter, multi }) {
  if ((!multi && !value) || (multi && !value?.length)) {
    return null;
  }
  return (
    <div>
      <SmallHeader
        style={{
          margin: `${svars.spaceSmall} 0`,
          paddingRight: svars.spaceMedium,
        }}
      >
        {label}
      </SmallHeader>
      <div style={{ paddingBottom: svars.spaceMediumLarge }}>
        {(multi && Array.isArray(value) && (
          <ListField formatter={formatter} values={value} />
        )) ||
          (value && formatter?.(value)) ||
          value}
      </div>
    </div>
  );
}

OneFilter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.node,
  ]),
  formatter: PropTypes.func,
  multi: PropTypes.bool,
};

OneFilter.defaultProps = { formatter: null, multi: true, value: null };

const ContainerSegment = styled(Segment)`
  &&& {
    width: ${({ fluid }) =>
      fluid ? `calc(100% - 2*${svars.spaceMedium})` : '400px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${svars.spaceMedium};
    overflow: hidden;
  }
`;

function FiltersSummary({
  title,
  titleAction,
  isEmpty,
  footerElement,
  children,
  emptyDataActionElement,
  fluid,
  style,
}) {
  return (
    <ContainerSegment style={style} fluid={fluid ? 'true' : null}>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginTop: 0,
            marginBottom: svars.spaceNormalLarge,
          }}
        >
          <Trans id={title} />
          {titleAction}
        </div>
        {isEmpty ? (
          <EmptyDataPage
            style={{ padding: `${svars.spaceNormalLarge} ${svars.spaceSmall}` }}
            i18nHeaderText={t({ id: 'no-filter-set' })}
            actionComponent={emptyDataActionElement}
            illustrationUrl={emptyFiltersUrl}
            maxHeight="8rem"
          />
        ) : (
          <div style={{ paddingRight: svars.spaceSmall, overflowY: 'auto' }}>
            {children}
          </div>
        )}
      </span>
      {footerElement ? (
        <span style={{ padding: 0, margin: '0 auto' }}>
          <Divider />
          {footerElement}
        </span>
      ) : null}
    </ContainerSegment>
  );
}

FiltersSummary.propTypes = {
  title: commonPropTypes.i18nText.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  emptyDataActionElement: PropTypes.node,
  footerElement: PropTypes.node,
  // Whether the segment should take the full width
  fluid: PropTypes.bool,
  style: PropTypes.shape({}),
  titleAction: PropTypes.node,
};

FiltersSummary.defaultProps = {
  footerElement: null,
  emptyDataActionElement: null,
  fluid: false,
  style: {},
  titleAction: null,
};

FiltersSummary.OneFilter = OneFilter;

export default FiltersSummary;
