import React, { Component } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Icon,
  Label,
  Message,
  Modal,
  Popup,
} from 'semantic-ui-react';
import styled from 'styled-components';

import { MediumSpacePaddedRow } from 'components/ui/Grid';
import Link from 'components/ui/Link';
import {
  ButtonPrimary,
  ButtonSecondaryAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { TextInput } from 'components/ui/inputs/TextInput';

import { capitalize, removePunctuation } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const TagList = styled.span`
  border-right: 1px solid rgba(34, 36, 38, 0.08);
  padding: ${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceNormal} 0;
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WordLabelContainer = styled(Label)`
  &&&& {
    display: inline-flex;
    background-color: ${(props) => svars.getTextBackground(props.wordsindex)};
    cursor: pointer;

    & :hover {
      transition: ${svars.transitionBase};
      color: ${svars.accentColor};
    }
  }
`;

const Container = styled.div`
  &&&&&& {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: inherit;
    min-height: 0;
  }
`;

const UnionWord = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  padding: ${svars.spaceSmall} ${svars.spaceNormal};
  font-size: ${svars.fontSizeBase};
  font-weight: ${svars.fontWeightMedium};
`;

const ValuesContainer = styled.div`
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
`;

class TextSearchFilter extends Component {
  constructor() {
    super();

    this.state = {
      textFilterValue: '',
      showWordIndexInModal: null,
      modalTextValue: '',
      inputValues: {},
      toggledInputWordsIndex: null,
    };
    this.handleTextInputKeyDown = this.handleTextInputKeyDown.bind(this);
    this.handleAlternativeWordInputKeyDown =
      this.handleAlternativeWordInputKeyDown.bind(this);
    this.renderWordAlternatives = this.renderWordAlternatives.bind(this);
    this.onToggleInputOrAddTerm = this.onToggleInputOrAddTerm.bind(this);
    this.onToggleWordAlternativeModal =
      this.onToggleWordAlternativeModal.bind(this);
  }

  handleTextInputKeyDown(event) {
    const {
      key,
      target: { value },
    } = event;
    const { onFilterChange, valueKey } = this.props;
    if (key === 'Enter') {
      event.preventDefault();
      const textValue = value ? value.trim() : null;
      const { textSearchValues } = this.props;
      if (textValue && removePunctuation(textValue).length) {
        const newTextSearchValues = [...textSearchValues, [textValue]];

        onFilterChange({ [valueKey]: newTextSearchValues });
        this.setState({
          textFilterValue: '',
        });
      }
    }
  }

  onToggleWordAlternativeModal(wordAlternativesIndex) {
    return () => this.setState({ showWordIndexInModal: wordAlternativesIndex });
  }

  onToggleInputOrAddTerm = (wordsIndex) => () => {
    const { onSetFilterActive } = this.props;
    const { toggledInputWordsIndex, inputValues } = this.state;
    onSetFilterActive(true);
    if (toggledInputWordsIndex === wordsIndex) {
      if (inputValues[wordsIndex]) {
        // Add term
        this.handleAlternativeWordInputKeyDown(wordsIndex)({
          key: 'Enter',
          target: { value: inputValues[wordsIndex] },
        });
      }
    } else {
      // Toggle input
      this.setState({
        toggledInputWordsIndex: wordsIndex,
      });
    }
  };

  onAddTermInputChange =
    (wordsIndex) =>
    (e, { value }) => {
      e.stopPropagation();
      const { inputValues } = this.state;
      this.setState({
        inputValues: { ...inputValues, [wordsIndex]: value },
      });
    };

  resetFilter = () => {
    const { onFilterChange, valueKey } = this.props;
    onFilterChange({ [valueKey]: [] });
  };

  onCloseModal = () => {
    const { onSetFilterActive } = this.props;
    onSetFilterActive();
    return this.onToggleWordAlternativeModal(null);
  };

  handleAlternativeWordInputKeyDown =
    (textSearchIndex) =>
    ({ key, target }) => {
      const { textSearchValues, onFilterChange, valueKey } = this.props;
      const { inputValues } = this.state;

      if (key === 'Enter') {
        if (target.value && removePunctuation(target.value).length) {
          const newTextSearchValues = [...textSearchValues];
          newTextSearchValues[textSearchIndex] = [
            ...newTextSearchValues[textSearchIndex],
            target.value,
          ];
          const textStateKey = 'modalTextValue';
          this.setState({ [textStateKey]: '' });
          this.setState({
            inputValues: { ...inputValues, [textSearchIndex]: '' },
          });
          onFilterChange({ [valueKey]: newTextSearchValues });
        }
      }
    };

  renderAlternativeWord(
    wordAlternative,
    wordAlternativesIndex,
    wordsIndex,
    openInModal = false,
    deletable = true,
    extraKey = 'kw'
  ) {
    const { onFilterChange, textSearchValues, valueKey } = this.props;
    const onDelete = () => {
      // Remove word by slicing array based on word index
      let updatedSearchValues = [...textSearchValues];
      if (updatedSearchValues[wordsIndex].length === 1) {
        updatedSearchValues = updatedSearchValues
          .slice(0, wordsIndex)
          .concat(
            updatedSearchValues.slice(
              wordsIndex + 1,
              updatedSearchValues.length
            )
          );
        if (openInModal) this.onToggleWordAlternativeModal(null)();
      } else {
        updatedSearchValues[wordsIndex] = updatedSearchValues[wordsIndex]
          .slice(0, wordAlternativesIndex)
          .concat(
            updatedSearchValues[wordsIndex].slice(
              wordAlternativesIndex + 1,
              updatedSearchValues[wordsIndex].length
            )
          );
      }
      onFilterChange({
        [valueKey]: updatedSearchValues,
      });
    };
    return (
      <WordLabelContainer
        key={`label-${extraKey}-${wordsIndex}-${wordAlternativesIndex}-${wordAlternative}`}
        wordsindex={wordsIndex}
        style={{
          margin: `0 ${svars.spaceSmall}`,
        }}
        onClick={deletable ? onDelete : this.onToggleInputOrAddTerm(wordsIndex)}
      >
        <div
          style={{ display: 'inline-flex', justifyContent: 'space-between' }}
        >
          <span
            style={{
              maxWidth: '70px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {wordAlternative}
          </span>
          <Icon
            style={{ margin: `0 0 0 ${svars.spaceNormal}` }}
            name={deletable ? 'close' : 'add'}
          />
        </div>
      </WordLabelContainer>
    );
  }

  renderWordAlternatives(words, wordsIndex) {
    const { onSetFilterActive } = this.props;
    const { inputValues } = this.state;
    return (
      <React.Fragment key={`wal-${wordsIndex}`}>
        <Label.Group
          key={`group-label-${wordsIndex}-${words[0]}`}
          style={{
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
            padding: `0 ${svars.spaceNormal}`,
          }}
        >
          <TagList>
            {words.map((word, i) =>
              this.renderAlternativeWord(
                word,
                i,
                wordsIndex,
                false,
                true,
                'taglist'
              )
            )}
          </TagList>
          <UnionWord key="sep-or">
            <Trans id="or" />
          </UnionWord>
          <Popup
            on="click"
            position="bottom left"
            onClose={() => onSetFilterActive(false)}
            trigger={
              <span style={{ display: 'inline-flex' }}>
                <SecondaryTabButton
                  icon="add"
                  onClick={this.onToggleInputOrAddTerm(wordsIndex)}
                  labelPosition="right"
                  content={<Trans id="add" />}
                  fitted="1"
                />
              </span>
            }
          >
            <div
              style={{
                maxHeight: '30vh',
                overflowY: 'auto',
                margin: svars.spaceNormal,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {words.map((word, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`kwi-${word}-${wordsIndex}-${i}`}
                  style={{ padding: `${svars.spaceSmall} 0` }}
                >
                  {this.renderAlternativeWord(
                    word,
                    i,
                    wordsIndex,
                    false,
                    true,
                    'baselist'
                  )}
                </div>
              ))}
            </div>
            <span style={{ fontStyle: 'italic' }}>
              <Trans id="add-an-alternative-keyword" /> :
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: svars.spaceNormal,
              }}
            >
              <TextInput
                autoFocus
                value={inputValues[wordsIndex] || ''}
                onKeyDown={this.handleAlternativeWordInputKeyDown(wordsIndex)}
                onChange={this.onAddTermInputChange(wordsIndex)}
                style={{
                  paddingTop: svars.spaceNormalLarge,
                  width: '100%',
                }}
                // action
              />
              <div style={{ textAlign: 'end' }}>
                <ButtonSecondaryAccent
                  fitted="true"
                  name="add"
                  style={{
                    // paddingTop: svars.spaceNormal,
                    // marginLeft: svars.spaceMedium,
                    margin: `${svars.spaceNormalLarge} 0`,
                  }}
                  onClick={this.onToggleInputOrAddTerm(wordsIndex)}
                >
                  <Trans id="add" />
                </ButtonSecondaryAccent>
              </div>
            </div>
          </Popup>
        </Label.Group>
      </React.Fragment>
    );
  }

  renderWordAlternativesModal() {
    const { textSearchValues } = this.props;
    const { showWordIndexInModal, modalTextValue } = this.state;
    if (showWordIndexInModal == null) return null;
    const addWordAlternative =
      this.handleAlternativeWordInputKeyDown(showWordIndexInModal);
    return (
      <Grid>
        <Grid.Column width={10}>
          <MediumSpacePaddedRow columns={1}>
            <Label.Group
              key={`model-group-label-${svars.TEXT_BACKGROUNDS[showWordIndexInModal]}-${textSearchValues[showWordIndexInModal][0]}`}
            >
              {textSearchValues[showWordIndexInModal].map(
                (word, wordAlternativesIndex) =>
                  this.renderAlternativeWord(
                    word,
                    wordAlternativesIndex,
                    showWordIndexInModal,
                    true,
                    true
                  )
              )}
            </Label.Group>
          </MediumSpacePaddedRow>
          <MediumSpacePaddedRow columns={1}>
            <Grid.Column
              style={{
                display: 'inline-flex',
                alignItems: 'end',
                width: '100%',
              }}
              verticalAlign="bottom"
            >
              <span
                style={{
                  fontWeight: svars.fontWeightMedium,
                  alignSelf: 'flex-end',
                  padding: `0 ${svars.spaceNormal} 0 0`,
                }}
              >
                <Trans id="dashboard-search.add-one-alternative-word" />
                &nbsp;:
              </span>
              <span style={{ marginTop: 'auto' }}>
                <TextInput
                  autoFocus
                  onKeyDown={addWordAlternative}
                  value={modalTextValue}
                  onChange={(e, { value }) =>
                    this.setState({ modalTextValue: value })
                  }
                  style={{
                    padding: `0 ${svars.spaceMedium}`,
                  }}
                />
              </span>
              <ButtonPrimary
                onClick={() =>
                  addWordAlternative({
                    key: 'Enter',
                    target: { value: modalTextValue },
                  })
                }
                style={{ marginLeft: svars.spaceLarge }}
              >
                <Trans id="add" />
              </ButtonPrimary>
            </Grid.Column>
          </MediumSpacePaddedRow>
        </Grid.Column>
        <Grid.Column width={6}>
          <MediumSpacePaddedRow columns={1}>
            <Message info>
              <Message.Header>
                <Trans id="dashboard-search.add-alternative-words" />
              </Message.Header>
              <p>
                <Trans id="dashboard-search.add-alternative-words-description" />
              </p>
            </Message>
          </MediumSpacePaddedRow>
        </Grid.Column>
      </Grid>
    );
  }

  render() {
    const { textSearchValues } = this.props;
    const { textFilterValue, showWordIndexInModal } = this.state;
    return (
      <Container>
        <ValuesContainer>
          {textSearchValues.length ? (
            <>
              {textSearchValues.map(this.renderWordAlternatives).reduce(
                (components, component) =>
                  components.length
                    ? [
                        ...components,
                        // eslint-disable-next-line react/jsx-indent
                        <Divider
                          style={{
                            margin: `${svars.spaceSmall} ${svars.spaceLarge}`,
                          }}
                          horizontal
                          key={`div-${component.key}`}
                        >
                          <Trans id="and" />
                        </Divider>,
                        component,
                      ]
                    : [component],
                []
              )}
              <Divider
                style={{
                  margin: `${svars.spaceSmall} 0`,
                }}
                key="div-last"
              />
            </>
          ) : null}
        </ValuesContainer>
        <span
          style={{
            display: 'flex',
            padding: `${svars.spaceSmall} ${svars.spaceMedium} ${svars.spaceNormal} ${svars.spaceMedium}`,
          }}
        >
          <TextInput
            fluid
            placeholder={capitalize(t({ id: 'add-a-keyword-group' }))}
            icon="search"
            onChange={(e, { value }) => {
              this.setState({ textFilterValue: value });
            }}
            value={textFilterValue}
            onKeyDown={this.handleTextInputKeyDown}
            style={{ flexGrow: 1 }}
          />
          <Link
            base="true"
            disabled={textSearchValues.length === 0}
            style={{ marginTop: 'auto', paddingLeft: svars.spaceMedium }}
            onClick={this.resetFilter}
          >
            <Trans id="reset" render={capitalizedTranslation} />
          </Link>
        </span>
        <Modal
          closeIcon
          onClose={this.onCloseModal}
          open={showWordIndexInModal != null}
        >
          <Modal.Header style={{ textTransform: 'capitalize' }}>
            <Trans id="keywords" />
          </Modal.Header>
          <Modal.Content style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            {this.renderWordAlternativesModal()}
          </Modal.Content>
          <Modal.Actions>
            <ButtonTransparentAccent type="submit" onClick={this.onCloseModal}>
              <Trans id="close" />
            </ButtonTransparentAccent>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

TextSearchFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  textSearchValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onSetFilterActive: PropTypes.func.isRequired,
  // Used to set the key in the filter object when value is changed
  valueKey: PropTypes.string,
};

TextSearchFilter.defaultProps = {
  textSearchValues: [],
  valueKey: 'textSearchValues',
};

export default TextSearchFilter;
