import React from 'react';

import PropTypes from 'prop-types';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import BeatingLoader from 'components/ui/BeatingLoader';

import { generateRandomInt, range } from 'utils/helpers';

import * as svars from 'assets/style/variables';

function LoadingDataVisualization({ height, width }) {
  return (
    <BeatingLoader
      white
      style={{
        borderRadius: svars.borderRadius,
        height,
        width,
        margin: 'auto',
      }}
    />
  );
}

LoadingDataVisualization.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
LoadingDataVisualization.defaultProps = { width: '100%', height: '100%' };

const BaseTagPlaceholder = styled(Placeholder.Line)(({ randint }) => {
  const left = 15 + 5 * randint;
  const wordLength = 10 + Math.min(2.5 * randint, 100 - left);
  const right = 100 - left - wordLength;
  const height = 0.7 * (1 + randint / 6);
  return `
  &&&&& {
    margin-bottom: ${height - 0.5}em;
    height: ${height}em;
    width: 100%;
    flex: 1 1 0;
    &:before {
      width: ${left}%;
      height: ${height}em;
    }

    &:after {
      width: ${right}%;
      height: ${height}em;
    }
  }
`;
});
const TagPlaceholder = React.memo(
  BaseTagPlaceholder,
  (prevProps, nextProps) => prevProps.randint !== nextProps.randint
);

export function LoadingTagCloud() {
  return (
    <Placeholder
      fluid
      style={{
        borderRadius: svars.borderRadius,
        height: '100%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      {range(12).map((key) => (
        <TagPlaceholder
          style={{
            backgroundColor: 'white',
          }}
          randint={generateRandomInt(0, 10)}
          key={`pl-${key}`}
        />
      ))}
    </Placeholder>
  );
}

export default LoadingDataVisualization;
