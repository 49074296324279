import { useCallback } from 'react';

import { t, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Sidebar } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';

import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';

import { useKeyPress } from 'utils/hooks';

import * as svars from 'assets/style/variables';
import commonPropTypes from 'utils/commonPropTypes';

const TARGET_OPACITY = 0.65;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: ${TARGET_OPACITY};
  }
`;

const Dimmer = styled.div`
  opacity: ${TARGET_OPACITY};
  background: ${({ color }) => color};
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${({ zindex }) => zindex};
  visibility: visible;
  animation: ${fadeIn} 200ms linear;
  transition: visibility 200ms linear;
`;

const StyledSidebar = styled(Sidebar)`
  &&&& {
    box-shadow: ${svars.strongBoxShadow};
    background: ${svars.backgroundColorBase};
    display: flex;
    flex-direction: column;
    padding: 0;
    /* Makes sure the pane is never larger than the screen */
    max-width: 100%;
  }
`;

function SidePane({
  children,
  width,
  visible,
  onToggle,
  locked,
  dimmed,
  dimmerColor,
  ...props
}) {
  const closeIfOpen = useCallback(
    (event) =>
      visible && !locked && onToggle() && event && event.preventDefault(),
    [visible, locked, onToggle]
  );
  useKeyPress('Escape', closeIfOpen);
  // If dimmed is boolean, set default value (100)
  const dimLevel = (dimmed === true && 100) || dimmed;

  return (
    <>
      <StyledSidebar width={width} visible={visible} {...props}>
        {children}
      </StyledSidebar>
      {(visible && dimmed !== false && (
        <Dimmer color={dimmerColor} zindex={dimLevel} onClick={closeIfOpen} />
      )) ||
        null}
    </>
  );
}

SidePane.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  width: PropTypes.oneOf(['wide', 'very wide']),
  // Lock the panel when visible, so it can't be closed
  locked: PropTypes.bool,
  // Whether to dim the background when the panel is visible
  dimmed: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  pusher: PropTypes.node,
  dimmerColor: PropTypes.string,
};
SidePane.defaultProps = {
  dimmerColor: svars.colorDarkGrey,
  width: 'wide',
  locked: false,
  visible: false,
  dimmed: false,
  pusher: null,
};

SidePane.Actions = styled.div`
  display: ${({ visible }) => (visible ? 'inline-flex' : 'none')};
  z-index: 2;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: ${svars.lightBorderStyle};
  padding: ${svars.spaceMedium} ${svars.spaceMediumLarge};
`;
SidePane.HeaderContainerLeftContainer = styled.div``;
SidePane.HeaderContainerRightContainer = styled.div``;
SidePane.HeaderContainer = styled.div`
  z-index: 3;
  height: ${svars.navbarHeight};
  display: inline-flex;
  align-items: center;
  background: ${svars.colorLightestGrey};
  padding: ${svars.spaceNormalLarge} ${svars.spaceMedium};
  justify-content: space-between;
  font-size: ${svars.fontSizeLarge};
  font-weight: ${svars.fontWeightLight};
  box-shadow: ${svars.primaryBoxShadow};
  justify-content: space-between;
`;
SidePane.Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${svars.backgroundColorBase};
  overflow: auto;
`;

function SidePaneHeader({
  title,
  onToggle,
  onSelectPrevious,
  onSelectNext,
  gaCategory,
  onSelectPreviousHelp,
  onSelectNextHelp,
}) {
  return (
    <SidePane.HeaderContainer>
      <span
        style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}
      >
        <AnalyticsAwareHoverableIconButtonWithTooltip
          data-testid="bo-close-preview-pane-iframe"
          gaCategory={gaCategory}
          gaAction="close panel"
          name="angle double right"
          onClick={onToggle}
          style={{ display: 'inline-flex', marginRight: svars.spaceNormal }}
          help={t({ id: "close-panel" })}
        />
        {title}
      </span>
      {onSelectNext ? (
        <span style={{ display: 'flex' }}>
          <AnalyticsAwareHoverableIconButtonWithTooltip
            gaCategory={gaCategory}
            gaAction="select previous"
            name="chevron up"
            onClick={onSelectPrevious}
            help={onSelectPreviousHelp}
            positionFixed
            disabled={!onSelectPrevious}
          />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            gaCategory={gaCategory}
            gaAction="select next"
            onClick={onSelectNext}
            name="chevron down"
            help={onSelectNextHelp}
          />
        </span>
      ) : (
        <span />
      )}
    </SidePane.HeaderContainer>
  );
}
SidePaneHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onToggle: PropTypes.func.isRequired,
  onSelectPrevious: PropTypes.func,
  onSelectNext: PropTypes.func,
  gaCategory: PropTypes.string.isRequired,
  onSelectPreviousHelp: commonPropTypes.i18nText,
  onSelectNextHelp: commonPropTypes.i18nText,
};
SidePaneHeader.defaultProps = {
  onSelectPrevious: null,
  onSelectNext: null,
  onSelectPreviousHelp: msg({ id: "previous" }),
  onSelectNextHelp: msg({ id: "next" }),
};
SidePane.Header = SidePaneHeader;
export default SidePane;
