import { Component } from 'react';

import { Trans } from '@lingui/react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ResponsiveContainer } from 'recharts';
import squarify from 'squarify';

import SentimentScale from 'components/ui/SentimentScale';

import { getInterpolatedColor, linearInterpolation } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import {
  floatFormatter,
  numberFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';
import { renderSvgTextSpans, svgShadowDef } from 'utils/svg';

import * as svars from 'assets/style/variables';

const sortData = (data) => {
  data.forEach((element) => {
    element.value = element.n_chunks;
  });
  data.sort((a, b) => (a.value < b.value ? 1 : -1));
};

const StyledTreemapBlock = styled.g`
  cursor: pointer;

  & rect {
    overflow: visible;
    stroke-width: ${svars.treemapHoverFrameWidth};
    stroke: ${svars.colorWhite};
    shape-rendering: crispEdges;
  }

  & text {
    fill: ${svars.fontColorBase};
    font-weight: ${svars.fontWeightBold};
  }
`;

const TooltipContainer = styled.g`
  transition: all 1s linear;
  // This is important to ensure tooltip is displayed even when it is hovered
  pointer-events: none;
`;

class Treemap extends Component {
  static resetData = (props) => {
    sortData(props.data);
    const container = {
      x0: 0,
      y0: 0,
      x1: props.width,
      y1: props.height - svars.treemapColorScaleHeight,
    };
    const squarifiedData = squarify(props.data, container);
    return {
      squarifiedData,
      maxOffsetX: Math.max(...squarifiedData.map(({ x1 }) => x1)),
      maxOffsetY: Math.max(...squarifiedData.map(({ y1 }) => y1)),
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      // Index of the hovered node in prop `data`
      hoveredNode: null,
      ...Treemap.resetData(props),
      // pos: {},
    };
    this.renderTreemapBlock = this.renderTreemapBlock.bind(this);
    this.getPosition = this.getPosition.bind(this);
    // We don't use state to store position to avoid many re-render of the component
    // Because of this, refresh happens only when a treemap block is entered or left
    this.cursorPosition = {};
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.getPosition);
  }

  componentDidUpdate(prevProps) {
    const { height, width, data } = this.props;
    if (
      prevProps.width !== width ||
      prevProps.height !== height ||
      prevProps.data !== data
    ) {
      this.setState(Treemap.resetData(this.props));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.getPosition);
  }

  getPosition = throttle((pos) => {
    this.cursorPosition = pos;
  }, 70);

  renderTreemapBlock(node) {
    const { id, x0, x1, y0, y1, label, average_sentiment } = node;
    const { onClick, nodeId } = this.props;
    const { hoveredNode } = this.state;
    const interpolatedColor = getInterpolatedColor(
      average_sentiment,
      linearInterpolation,
      svars.absoluteMinColor,
      svars.absoluteMaxColor,
      svars.absoluteMidColor
    );
    const isHovered = (hoveredNode && hoveredNode.id === id) || nodeId === id;
    const coloredBlockWidth = Math.max(
      1,
      x1 - x0 - 2 * svars.treemapHoverFrameWidth
    );
    const coloredBlockHeight = Math.max(
      1,
      y1 - y0 - 2 * svars.treemapHoverFrameWidth
    );
    return (
      <StyledTreemapBlock key={`treemap-block-${x0}-${id}`}>
        <rect
          onClick={() => onClick(id)}
          x={x0}
          y={svars.treemapColorScaleHeight + y0}
          width={x1 - x0}
          height={y1 - y0}
          fill={interpolatedColor}
          rx="8"
          ry="8"
          onMouseEnter={() => this.setState({ hoveredNode: node })}
          onMouseLeave={() => {
            if (hoveredNode && hoveredNode === node) {
              this.setState({ hoveredNode: null });
            }
          }}
        />
        <rect
          x={x0 + svars.treemapHoverFrameWidth}
          y={svars.treemapColorScaleHeight + y0 + svars.treemapHoverFrameWidth}
          width={coloredBlockWidth}
          height={coloredBlockHeight}
          filter={isHovered ? 'url(#inset)' : undefined}
          fill="transparent"
          rx="3"
          ry="3"
          style={{ pointerEvents: 'none' }}
        />
        <text
          x={(x1 - x0) / 2 + x0}
          y={svars.treemapColorScaleHeight + (y1 - y0) / 2 + y0}
          dominantBaseline="middle"
          alignmentBaseline="hanging"
          textAnchor="middle"
          style={{ pointerEvents: 'none' }}
        >
          {renderSvgTextSpans(
            label,
            x0 + 2 * svars.treemapHoverFrameWidth,
            x0 + coloredBlockWidth + 1,
            svars.treemapColorScaleHeight +
              y0 +
              2 * svars.treemapHoverFrameWidth,
            svars.treemapColorScaleHeight +
              y1 -
              2 * svars.treemapHoverFrameWidth,
            interpolatedColor,
            true
          )}
        </text>
      </StyledTreemapBlock>
    );
  }

  renderTooltip = (node) => {
    if (
      typeof this.cursorPosition.offsetX !== 'number' ||
      typeof this.cursorPosition.offsetY !== 'number'
    ) {
      return null;
    }
    const {
      tooltipVolumeLabel,
      tooltipSentimentLabel,
      tooltipShareOfExtractsLabel,
    } = this.props;
    const { maxOffsetX, maxOffsetY } = this.state;
    // Select tooltip coordinates so the tooltip is never outside of the treemap span
    const coords = {
      x: Math.min(
        this.cursorPosition.offsetX + svars.treemapTooltipCursorDistance,
        maxOffsetX - svars.treemapTooltipWidth
      ),
      y: Math.min(
        this.cursorPosition.offsetY + svars.treemapTooltipCursorDistance,
        maxOffsetY - svars.treemapTooltipHeight
      ),
    };
    const textSpans = renderSvgTextSpans(
      node.label,
      coords.x,
      coords.x + svars.treemapTooltipWidth,
      coords.y,
      coords.y + svars.treemapTooltipHeight,
      null,
      false,
      {
        fontWeight: svars.fontWeightBold,
        dx: svars.treemapTooltipPadding,
      }
    );
    // We use the number of text spans to define positions of the two following rows
    const nChunksRowDy =
      (textSpans.length + 2) * svars.treemapTooltipPadding +
      svars.textSpanHeight;
    const sentimentRowDy =
      (textSpans.length + 3) * svars.treemapTooltipPadding +
      2 * svars.textSpanHeight;
    const shareOfExtractsRowDy =
      (textSpans.length + 4) * svars.treemapTooltipPadding +
      3 * svars.textSpanHeight;
    return (
      <TooltipContainer>
        <rect
          id="treemap-tooltip"
          {...coords}
          height={
            svars.treemapTooltipPadding * 6 +
            (textSpans.length + 3) * svars.textSpanHeight
          }
          width={svars.treemapTooltipWidth}
          rx="8"
          ry="8"
          fill={svars.colorWhite}
          filter="url(#boxShadow)"
        />
        <text
          {...coords}
          textAnchor="start"
          alignmentBaseline="hanging"
          dominantBaseline="hanging"
          height={svars.treemapTooltipHeight}
          width={svars.treemapTooltipWidth}
        >
          {textSpans}
          <tspan {...coords} dx={svars.treemapTooltipPadding} dy={nChunksRowDy}>
            <Trans id={tooltipVolumeLabel} render={capitalizedTranslation} />
          </tspan>
          <tspan
            {...coords}
            dx={svars.treemapTooltipWidth - svars.treemapTooltipPadding - 5}
            dy={nChunksRowDy}
            textAnchor="end"
          >
            {numberFormatter(node.n_chunks)}
          </tspan>
          <tspan
            {...coords}
            dx={svars.treemapTooltipPadding}
            dy={sentimentRowDy}
          >
            <Trans id={tooltipSentimentLabel} render={capitalizedTranslation} />
          </tspan>
          <tspan
            {...coords}
            dx={svars.treemapTooltipWidth - svars.treemapTooltipPadding - 5}
            dy={sentimentRowDy}
            textAnchor="end"
          >
            {floatFormatter(node.average_sentiment)}
          </tspan>
          <tspan
            {...coords}
            dx={svars.treemapTooltipPadding}
            dy={shareOfExtractsRowDy}
          >
            <Trans
              id={tooltipShareOfExtractsLabel}
              render={capitalizedTranslation}
            />
          </tspan>
          <tspan
            {...coords}
            dx={svars.treemapTooltipWidth - svars.treemapTooltipPadding - 5}
            dy={shareOfExtractsRowDy}
            textAnchor="end"
          >
            {zeroPrecisionPercentFormatter(node.share_of_extracts)}
          </tspan>
        </text>
      </TooltipContainer>
    );
  };

  render() {
    const { width, height, showScale, minSentiment, maxSentiment } = this.props;
    const { squarifiedData, hoveredNode } = this.state;
    return (
      <svg
        x={0}
        y={0}
        width={width}
        height={height}
        style={{ overflow: 'visible' }}
      >
        <defs>
          <filter id="inset" x="-50%" y="-50%" width="400%" height="400%">
            <feFlood
              floodColor={svars.treemapHoverFrameColor}
              result="inside-color"
            />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="inside-stroke"
            />
          </filter>
          <filter id="filter1" x="0" y="0">
            <feOffset result="offOut" in="SourceAlpha" dx="-20" dy="-20" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        {svgShadowDef}
        {showScale && (
          <SentimentScale
            id="treemap"
            minSentiment={minSentiment}
            maxSentiment={maxSentiment}
            cursorSentiment={hoveredNode ? hoveredNode.average_sentiment : null}
          />
        )}
        <svg y={svars.treemapHoverFrameWidth}>
          {squarifiedData.map(this.renderTreemapBlock)}
        </svg>
        {hoveredNode != null && this.renderTooltip(hoveredNode)}
      </svg>
    );
  }
}

export const treemapItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  n_chunks: PropTypes.number,
  average_sentiment: PropTypes.number,
});

Treemap.propTypes = {
  data: PropTypes.arrayOf(treemapItemPropTypes).isRequired,
  // The selected node id (to control selection frame)
  tooltipSentimentLabel: commonPropTypes.i18nText.isRequired,
  tooltipVolumeLabel: commonPropTypes.i18nText.isRequired,
  tooltipShareOfExtractsLabel: commonPropTypes.i18nText.isRequired,
  nodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  showScale: PropTypes.bool,
  minSentiment: PropTypes.number,
  maxSentiment: PropTypes.number,
};

Treemap.defaultProps = {
  nodeId: null,
  showScale: true,
  onClick: () => {},
  minSentiment: -1.0,
  maxSentiment: 1.0,
  height: undefined,
  width: undefined,
};

function ResponsiveTreemap({ width, height, ...otherProps }) {
  return (
    <ResponsiveContainer width={width} height={height}>
      <Treemap {...otherProps} />
    </ResponsiveContainer>
  );
}

ResponsiveTreemap.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ResponsiveTreemap.defaultProps = {
  height: 400,
  width: '100%',
};

export default ResponsiveTreemap;
