import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import styled from 'styled-components';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';
import { analysisIcon, campaignIcon } from 'assets/style/variables';

const TitleWrapper = styled(Card.Header)`
  display: flex;
  align-items: center;
  max-width: 90%;
  justify-content: space-between;
  line-height: normal;
`;

const TitleText = styled.span`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: wrap;
  color: ${(props) => (props.isRead ? svars.colorGrey : svars.colorBlack)};
  font-weight: ${svars.fontWeightMedium};
  font-size: ${svars.fontSizeLarge};
`;

function PresetUserNotification({ isRead, preset, condition_delta }) {
  const field = preset.notification?.condition_field;
  const isDecrease = condition_delta < 0;
  let changeSymbol = null;
  if (condition_delta) {
    changeSymbol = isDecrease ? '' : '+';
  }
  const isPercentage = preset.notification?.is_threshold_relative ? '%' : null;
  const message =
    (field === 'n_chunks' && isDecrease && t({ id: 'volume-decrease' })) ||
    (field === 'n_chunks' && !isDecrease && t({ id: 'volume-increase' })) ||
    (field === 'average_sentiment' &&
      isDecrease &&
      t({ id: 'sentiment-decrease' })) ||
    (field === 'average_sentiment' &&
      !isDecrease &&
      t({ id: 'sentiment-increase' })) ||
    (!field && t({ id: 'new-analyzed-extracts' })) ||
    null;

  return (
    <TitleText isRead={isRead}>
      <Trans id={message} render={capitalizedTranslation} />
      {condition_delta ? (
        <>
          <span> : </span>
          <span style={{ fontWeight: svars.fontWeightExtraBold }}>
            {`${changeSymbol}${condition_delta}`}
            {isPercentage}
          </span>
        </>
      ) : null}
    </TitleText>
  );
}

PresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  condition_delta: PropTypes.number.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string.isRequired,
    view_facet: PropTypes.shape({ name: PropTypes.string }),
    notification: PropTypes.shape({
      current_statistics: PropTypes.shape({ n_chunks: PropTypes.number }),
      condition_field: PropTypes.string,
      is_threshold_relative: PropTypes.bool,
    }),
  }).isRequired,
};

function CampaignPresetUserNotification({ isRead, n_new_reviews }) {
  const nNewResponses = n_new_reviews;
  return (
    <TitleText isRead={isRead}>
      <Trans id="new-campaign-responses" render={capitalizedTranslation} />
      <span> : </span>
      <span style={{ fontWeight: svars.fontWeightExtraBold }}>
        +{nNewResponses}
      </span>
    </TitleText>
  );
}
CampaignPresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  n_new_reviews: PropTypes.string.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    campaign: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function UserAssignment({ isRead, ...otherProps }) {
  return (
    <TitleText isRead={isRead}>
      <Trans>
        {otherProps?.source} assigned a response to you in [
        {otherProps?.campaignName || otherProps?.analysisName}]
      </Trans>
    </TitleText>
  );
}
UserAssignment.propTypes = {
  isRead: PropTypes.bool.isRequired,
};

const titleComponents = {
  PresetUserNotification,
  CampaignPresetUserNotification,
};

const typeIcons = {
  PresetUserNotification: analysisIcon,
  CampaignPresetUserNotification: campaignIcon,
};

const typeKeys = Object.keys(typeIcons);

function NotificationTitle({ type, isRead, ...otherProps }) {
  const TitleComponent =
    titleComponents[type] ||
    (() => <TitleText isRead={isRead}>{otherProps}</TitleText>);
  return (
    <TitleWrapper>
      <TitleComponent isRead={isRead} {...otherProps} />
    </TitleWrapper>
  );
}

NotificationTitle.propTypes = {
  type: PropTypes.oneOf(typeKeys).isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationTitle;
