import PropTypes from 'prop-types';

import { getCSATScoreColor } from 'utils/colors';

const DOT_SIZE = 12;
function CustomizedDot({ cx, cy, value, fillOpacity, strokeOpacity }) {
  if (value === null) {
    return null;
  }

  return (
    <svg
      x={cx - DOT_SIZE / 2}
      y={cy - DOT_SIZE / 2}
      width={DOT_SIZE}
      height={DOT_SIZE}
      viewBox="0 0 10 10"
    >
      <circle
        cx="5"
        cy="5"
        r="4"
        fill={getCSATScoreColor(value)}
        fillOpacity={fillOpacity}
        strokeOpacity={strokeOpacity}
        strokeWidth="1.5"
        stroke="white"
      />
    </svg>
  );
}

CustomizedDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  value: PropTypes.number,
  fillOpacity: PropTypes.number,
  strokeOpacity: PropTypes.number,
};
CustomizedDot.defaultProps = {
  cx: null,
  cy: null,
  value: 0,
  fillOpacity: 1,
  strokeOpacity: 1,
};

export default CustomizedDot;
