import { useCallback } from 'react';

import PropTypes from 'prop-types';

import CheckboxList, {
  baseListItemProps,
} from 'components/ui/inputs/checkbox-list';

import * as svars from 'assets/style/variables';

const FILTER_HEADER_STYLES = {
  padding: `0 ${svars.spaceNormal} ${svars.spaceSmall} ${svars.spaceNormal}`,
};

function CheckboxListFilter({
  items,
  values,
  valueKey,
  onFilterChange,
  onFilterReset,
  isSelectedItem,
  loading,
  searchPlaceholder,
  ...otherCheckboxListProps
}) {
  const onSelectItem = useCallback(
    ({ value }) =>
      onFilterChange({
        [valueKey]: [...values, value],
      }),
    [values, valueKey, onFilterChange]
  );
  const onSelectItems = useCallback(
    (selectedItems) =>
      onFilterChange({
        [valueKey]: [
          ...values,
          ...selectedItems
            .filter((item) => !isSelectedItem(item, values))
            .map(({ value }) => value),
        ],
      }),
    [values, onFilterChange]
  );
  const onUnselectItem = useCallback(
    (target) => {
      onFilterChange({
        [valueKey]: values.filter(
          (itemValue) => !isSelectedItem(target, [itemValue])
        ),
      });
    },
    [values, onFilterChange]
  );
  const nItems = items.length;
  return (
    <CheckboxList
      items={items}
      loading={loading}
      onSelectItem={onSelectItem}
      onSelectItems={onSelectItems}
      onUnselectItem={onUnselectItem}
      onResetItems={onFilterReset([valueKey], [])}
      isSelectedItem={isSelectedItem}
      selectedItems={values}
      placeholder={searchPlaceholder}
      searchable={nItems > 4}
      headerStyle={FILTER_HEADER_STYLES}
      {...otherCheckboxListProps}
    />
  );
}
CheckboxListFilter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...baseListItemProps,
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object])
  ),
  // The key to use to update value
  valueKey: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  isSelectedItem: PropTypes.func,
  loading: PropTypes.bool,
};
CheckboxListFilter.defaultProps = {
  items: [],
  values: [],
  loading: false,
  searchPlaceholder: null,
  isSelectedItem: ({ value }, selectedValues) => selectedValues.includes(value),
};
export default CheckboxListFilter;
