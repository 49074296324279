import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsAwareHoverableIconButton } from '../icon/HoverableIcon';

function HelpcrunchWidgetIconButton() {
  const [displayChatWidget, setDisplayChatWidget] = useState(false);
  const location = useLocation();
  useEffect(() => window.HelpCrunch?.('onChatClose', () => {
    setDisplayChatWidget(false);
    window.HelpCrunch?.('hideChatWidget');
  }), []);
  const onClickHelp = useCallback(() => {
    setDisplayChatWidget(false);
    if (!displayChatWidget) {
      window.HelpCrunch?.('openChat');
      setDisplayChatWidget(true);
    } else {
      window.HelpCrunch?.('closeChat');
      window.HelpCrunch?.('hideChatWidget');
      setDisplayChatWidget(false);
    }
  }, [displayChatWidget]);
  return (
    <AnalyticsAwareHoverableIconButton
      name="question circle outline"
      onClick={onClickHelp}
      gaCategory="Nav bar"
      gaAction={displayChatWidget ? 'Close FAQ' : 'Open FAQ'}
      gaLabel={`path=${location.pathname}`}
      active={displayChatWidget}
    />
  );
}

HelpcrunchWidgetIconButton.propTypes = {};
HelpcrunchWidgetIconButton.defaultProps = {};

export default HelpcrunchWidgetIconButton;
