import React from 'react';

import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import annyang from 'annyang';

import startRecordingSound from 'assets/sound/start-recording.wav';

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 2px rgb(255, 187, 180);
  }

  50% {
     box-shadow: 0 0 0 7px rgb(255, 187, 180);
  }
  100% {
     box-shadow: 0 0 0 2px rgb(255, 187, 180);
  }
`;

const Button = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-grow: 2;
  border-radius: 50%;
  background: #f32;
  box-shadow: 0 0 0 ${(props) => (props.active ? '1px' : '4px')}
    rgb(255, 187, 180);
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      animation: ${pulse} 0.8s infinite ease;
    `};
`;

export default class Voice extends React.Component {
  constructor(props) {
    super(props);
    this.toggleRecording = this.toggleRecording.bind(this);
  }

  componentDidMount() {
    const { setTranscript } = this.props;
    annyang.setLanguage('fr-FR');

    annyang.debug();
    annyang.addCallback('result', ([userText]) => {
      setTranscript(userText);
    });
  }

  toggleRecording() {
    const { isRecording, setIsRecording } = this.props;
    if (!isRecording) {
      const audio = new Audio(startRecordingSound);

      audio.play();
      annyang.start();
    } else {
      annyang.pause();
    }
    setIsRecording(!isRecording);
  }

  render() {
    const { isRecording } = this.props;

    return <Button active={isRecording} onClick={this.toggleRecording} />;
  }
}

Voice.propTypes = {
  isRecording: PropTypes.bool.isRequired,
  setTranscript: PropTypes.func.isRequired,
  setIsRecording: PropTypes.func.isRequired,
};
