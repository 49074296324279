import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import {
  categoryHelpMessage,
  volumeHelpMessage,
} from 'components/customer/v2/helpMessageV2';

import { accentFold } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import ReactTableHeader from './ReactTableHeader';
import {
  ReactTableSentimentCell,
  ReactTableTextCell,
  ValueIndicatorCell,
  reactTableVolumeCellFactory,
} from './cells/ReactTableCell';
import ShareOfExtractTableCell from './cells/ShareOfExtractTableCell';

export const CellContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const getColumnFilterMethod = (filterKeyOrMethod) => (filter, row) => {
  if (!filter) return false;
  const rowValue =
    typeof filterKeyOrMethod === 'string'
      ? row[filterKeyOrMethod]
      : filterKeyOrMethod(row);
  const accentFolded = rowValue ? accentFold(rowValue.toLowerCase()) : '';
  return accentFolded.includes(accentFold(filter.toLowerCase()));
};

const BASE_REACT_TABLE_COLUMNS = {
  concept: () => ({
    id: 'concept',
    Cell: ReactTableTextCell,
    Header: () => (
      <ReactTableHeader
        title={t({ id: "category" })}
        helpMessage={<Trans id={categoryHelpMessage} />}
      />
    ),
    minWidth: 80,
    width: 180,
    maxWidth: 330,
    accessor: 'label',
    filterable: true,
    filterMethod: getColumnFilterMethod('name'),
  }),
  product_hierarchy: () => ({
    id: 'product_hierarchy',
    Header: () => (
      <ReactTableHeader
        title={t({ id: "product-service" })}
        helpMessage={t({ id: "base-group-product-service" })}
      />
    ),
    Cell: ReactTableTextCell,
    width: 200,
    maxWidth: 400,
    style: {
      paddingLeft: svars.spaceMedium,
      alignItems: 'center',
      display: 'flex',
    },
    accessor: 'product_hierarchy',
    filterable: true,
    filterMethod: getColumnFilterMethod('product_hierarchy'),
  }),
  n_chunks: (maxNChunks) => ({
    id: 'n_chunks',
    sortType: 'basic',
    Header: () => (
      <ReactTableHeader
        title={t({ id: "volume" })}
        helpMessage={<Trans id={volumeHelpMessage} />}
      />
    ),
    width: 120,
    maxWidth: 200,
    resizable: false,
    centered: true,
    filterable: false,
    accessor: 'n_chunks',
    Cell: reactTableVolumeCellFactory(maxNChunks),
  }),
  share_of_extracts: () => ({
    id: 'share_of_extracts',
    sortType: 'basic',
    Header: () => (
      <ReactTableHeader
        title={t({ id: "share-of-extracts" })}
        helpMessage={<Trans id="help-message.share-of-extracts-description" />}
      />
    ),
    width: 120,
    maxWidth: 200,
    resizable: false,
    centered: true,
    filterable: false,
    accessor: 'share_of_extracts',
    Cell: ShareOfExtractTableCell,
  }),
  average_sentiment: () => ({
    id: 'average_sentiment',
    sortType: 'basic',
    Header: () => (
      <ReactTableHeader
        title={t({ id: "sentiment" })}
        helpMessage={<Trans id="help-message.average-sentiment-description" />}
      />
    ),
    centered: true,
    width: 120,
    maxWidth: 200,
    resizable: false,
    filterable: false,
    accessor: 'average_sentiment',
    Cell: ReactTableSentimentCell,
  }),
  differential_sentiment: (someVol, averageSentiment) => ({
    id: 'differential_sentiment',
    sortType: 'basic',
    accessor: (item) => item.average_sentiment - averageSentiment,
    Cell: ValueIndicatorCell,
    centered: true,
    width: 120,
    maxWidth: 200,
    resizable: false,
    Header: () => (
      <ReactTableHeader
        title={t({ id: "differential-sentiment" })}
        helpMessage={
          <Trans id="help-message.benchmark-differential-sentiment-description" />
        }
      />
    ),
  }),
};

export const useReactTableColumns = (
  columnsMap,
  maxNChunks = 1,
  globalAverageSentiment = 0
) =>
  Object.entries(columnsMap).map(([key, column]) => ({
    ...((BASE_REACT_TABLE_COLUMNS[key] &&
      BASE_REACT_TABLE_COLUMNS[key](maxNChunks, globalAverageSentiment)) ||
      {}),
    ...column,
  }));
