import { useCallback, useEffect, useState } from 'react';

import { getColumnFilterMethod } from 'components/ui/table/ReactTableColumns';

import { useDebounce } from 'utils/hooks';

/**
 * Hook to use debounced text filter method on a given field
 *
 * @param {Array<Object>} data the react-table data object.
 * @param {CallableFunction | String} labelAccessor the row key where searchable field is stored.
 * @param {Array<Any>} extraSearchTriggers a list of variable which updates should trigger search update.
 * @param {CallableFunction} extraFilterMethod an optional method to apply when refreshing filterData.
 * @return {*}
 */
const useTableFilter = (
  data,
  labelAccessor,
  extraSearchTriggers = [],
  extraFilterMethod = null
) => {
  const [filteredData, setFilteredData] = useState(data);

  const [textFilterValue, setTextFilterValue] = useState('');

  const filterRows = useCallback(({ target: { value } }) => {
    // NOTE: this completely clears any COLUMN filters
    setTextFilterValue(value);
  }, []);
  const debouncedTextFilterValue = useDebounce(textFilterValue, 250);
  useEffect(() => {
    if (data) {
      if (textFilterValue || extraFilterMethod) {
        setFilteredData(
          data.filter(
            (row) =>
              (!extraFilterMethod || extraFilterMethod(row)) &&
              (!textFilterValue ||
                getColumnFilterMethod(labelAccessor)(textFilterValue, row))
          )
        );
      } else {
        setFilteredData(data);
      }
    }
  }, [
    data,
    debouncedTextFilterValue,
    // textFilterValue,
    extraFilterMethod,
    ...extraSearchTriggers,
  ]);
  return [filteredData, textFilterValue, filterRows];
};

export default useTableFilter;
