

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zeroPrecisionPercentFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

import { CellContainer } from './ReactTableCell';

// Use a common unit to compute (circular) dimensions of the component
// This is to avoid css artifacts when the component is resized
const unitInPx = 6;
const circleWidth = `${6 * unitInPx}px`;
const circleHeight = `${3 * unitInPx}px`;
const circleBorderWidth = `${unitInPx}px`;
const circleBorderRadius = `${4 * unitInPx}px`;

const CircleOuter = styled.div`
  width: ${circleWidth};
  height: ${circleHeight};
  border-top-left-radius: ${circleBorderRadius};
  border-top-right-radius: ${circleBorderRadius};
  background-color: transparent;
  border: ${circleBorderWidth} solid ${svars.volumeColor};
  border-bottom: 0;
  position: relative;
  backface-visibility: hidden;
`;

const Circle = styled.div`
  width: ${circleWidth};
  height: ${circleHeight};
  border-top-left-radius: ${circleBorderRadius};
  border-top-right-radius: ${circleBorderRadius};
  transform: ${({ value }) => `rotate(${value * 180}deg)`};
  background-color: transparent;
  border: ${circleBorderWidth} solid ${svars.colorLightGrey};
  border-bottom: 0;
  backface-visibility: hidden;
  transition: all 1s ease;

  transform-origin: bottom;
  position: absolute;
  z-index: 1;

  bottom: 0;
  left: -${circleBorderWidth};
`;

const IndicatorContainer = styled.span`
  text-align: center;
  min-width: ${circleWidth};
  backface-visibility: hidden;
  margin-right: ${svars.spaceNormal};
`;

function ShareOfExtractWheel({ value }) {
  return (
    <div style={{ overflow: 'hidden' }}>
      <CircleOuter>
        <Circle value={value} />
      </CircleOuter>
    </div>
  );
}

ShareOfExtractWheel.propTypes = {
  value: PropTypes.number.isRequired,
};

export function ShareOfExtractTableCell({ value }) {
  return (
    <CellContainer
      style={{
        fontWeight: svars.fontWeightLight,
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      <IndicatorContainer>
        {zeroPrecisionPercentFormatter(value)}
      </IndicatorContainer>
      <ShareOfExtractWheel value={value} />
    </CellContainer>
  );
}
ShareOfExtractTableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ShareOfExtractTableCell;
