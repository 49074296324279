import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { questionLabelFormatterSelectorFactory } from 'selectors/survey';

import { LimitedTextCell } from 'components/ui/Text';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import { cellPropTypes } from './ReactTableCell';

const TypeLabel = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: ${svars.spaceNormal};
  border-radius: ${svars.roundedBorderRadius};
  font-size: {svars.fontSizeSmaller};
  font-weight: {svars.fontWeightMedium};
  color: inherit;
  background-color: ${({ color }) => color};
`;

function CampaignTypeCell({ value, basic }) {
  const isSurvey = value === 'SurveyCampaign';
  return (
    <TypeLabel
      basic={basic}
      color={isSurvey ? svars.colorSurvey : svars.colorFeedback}
    >
      {(value && (
        <Trans
          render={capitalizedTranslation}
          id={value === 'SurveyCampaign' ? 'survey' : 'feedback'}
        />
      )) ||
        '-'}
    </TypeLabel>
  );
}

CampaignTypeCell.propTypes = cellPropTypes;
CampaignTypeCell.defaultProps = {
  formatter: null,
  basic: null,
};

export default CampaignTypeCell;

export function HiddenQuestionsCell({ value }) {
  const { campaignId } = useParams();
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId)
  );
  return (
    <LimitedTextCell
      style={{ maxHeight: svars.listMaxHeight, overflow: 'clip auto' }}
    >
      {value
        ?.map(({ id }) => id)
        .map(questionLabelFormatter)
        .map((label) => <div key={label}>{label}</div>) || '-'}
    </LimitedTextCell>
  );
}

HiddenQuestionsCell.propTypes = cellPropTypes;
HiddenQuestionsCell.defaultProps = {
  formatter: null,
};
