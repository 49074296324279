const getVariable = (name) => (window.configVariables || {})[name];

// import configVariables from 'configVariables';
export default {
  supportEmail: 'support@better-world.io',
  API_URL: getVariable('API_URL') || 'http://localhost.better-world.io:5001',
  PREVIEW_URL:
    getVariable('PREVIEW_URL') || 'http://localhost.better-world.io:3000',
  env: getVariable('NODE_ENV') || 'development',
  logEnabled: false,
  // getVariable('REDUX_LOG_ENABLED') || process.env.NODE_ENV === 'development',
  ga: {
    property: getVariable('GA_PROPERTY'),
  },
  amplitude: {
    key: getVariable('AMPLITUDE_API_KEY'),
  },
  SENTIMENT_DOMAIN: [
    getVariable('MIN_SENTIMENT')
      ? parseFloat(getVariable('MIN_SENTIMENT'))
      : -1.0,
    getVariable('MAX_SENTIMENT')
      ? parseFloat(getVariable('MAX_SENTIMENT'))
      : 1.0,
  ],
  FORCE_CUSTOM_LOCALE: getVariable('FORCE_CUSTOM_LOCALE'),
  sentry: {
    authToken: getVariable('SENTRY_AUTH_TOKEN'),
    dsn: getVariable('SENTRY_DSN'),
  },
  // FORCE_SENTRY: true,
  // FORCE_CUSTOM_LOCALE: 'fr-pole-emploi',
};
