import { Trans, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SentimentCell from 'components/ui/cells/SentimentCell';

import { numberFormatter } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as styleVariables from 'assets/style/variables';
import commonPropTypes from 'utils/commonPropTypes';

const Container = styled.div`
  display: inline-flex;
  max-width: 180px;
  justify-content: space-between;
  color: ${styleVariables.fontColorBase};
  opacity: ${(props) =>
    props.disabled ? styleVariables.disabledOpacity : 'inherit'};
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
`;
const StatisticLabel = styled.div`
  font-style: italic;
  font-size: ${styleVariables.fontSizeSmaller};
  font-weight: ${styleVariables.fontWeightBase};
`;
const StatisticValue = styled.div`
  font-weight: ${styleVariables.fontWeightBold};
  font-size: ${styleVariables.fontSizeLarge};
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  white-space: pre;
`;

function Statistics({
  nChunks,
  nMaxChunks,
  averageSentiment,
  disabled,
  nChunksLabel,
  style,
}) {
  const nMaxChunksReached = (nChunks && nMaxChunks === nChunks) || false;
  return (
    <Container disabled={disabled} style={style}>
      <Item>
        <StatisticValue data-testid="chunk-value">
          {nMaxChunksReached ? '＞' : ''}
          {nChunks ? numberFormatter(nChunks) : '-'}
        </StatisticValue>
        <StatisticLabel>
          <Trans id={nChunksLabel} />
        </StatisticLabel>
      </Item>
      <Item style={{ paddingLeft: styleVariables.spaceMediumLarge }}>
        <SentimentCell sentiment={averageSentiment} />
        <StatisticLabel>
          <Trans render={capitalizedTranslation} id="sentiment" />
        </StatisticLabel>
      </Item>
    </Container>
  );
}

Statistics.propTypes = {
  nChunks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nMaxChunks: PropTypes.number,
  averageSentiment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  nChunksLabel: commonPropTypes.i18nText,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
Statistics.defaultProps = {
  nChunks: '-',
  nMaxChunks: null,
  averageSentiment: null,
  disabled: false,
  nChunksLabel: msg({ id: 'volume' }),
  style: {},
};

export default Statistics;
