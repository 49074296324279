import { shallowEqual, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import FiltersAccordion from 'components/ui/search/FiltersAccordion';

import commonPropTypes from 'utils/commonPropTypes';

import CheckboxListFilter from '../search/CheckboxListFilter';

const tagSetFieldFilterFactory = (
  itemsSelector,
  valuesSelector,
  onUpdateTagsFilters,
  onResetFilters,
  loading
) => {
  function TagSetFieldFilter({
    itemId,
    valueId,
    header,
    icon,
    iconHelp,
    feedbackFormValuesFieldName,
    type,
    isActive,
    onToggle,
  }) {
    const values = useSelector(
      valuesSelector({
        id: valueId,
        tagSetId: itemId,
        feedbackFormValuesFieldName,
      }),
      shallowEqual
    );
    const items = useSelector(itemsSelector(itemId, valueId), shallowEqual);
    const onFilterChange = onUpdateTagsFilters(
      feedbackFormValuesFieldName,
      valueId
    );
    // Only other type of field is text, and does not have a related tag set
    const isTextField = type === 'text' || !valueId;

    return (
      <FiltersAccordion.Filter
        title={header}
        icon={icon}
        iconHelp={iconHelp}
        nFilterValues={values ? values.length : 0}
        isActive={isActive}
        onToggle={onToggle}
      >
        {!isTextField ? (
          <CheckboxListFilter
            valueKey="value"
            searchPlaceholder={t({ id: 'search-a-tag-label' })}
            items={items || []}
            values={values}
            onFilterChange={onFilterChange}
            onFilterReset={onResetFilters(
              valueId,
              true,
              feedbackFormValuesFieldName
            )}
            loading={loading}
          />
        ) : null}
      </FiltersAccordion.Filter>
    );
  }
  TagSetFieldFilter.propTypes = {
    itemId: PropTypes.string,
    valueId: PropTypes.string,
    header: PropTypes.string.isRequired,
    feedbackFormValuesFieldName: PropTypes.string,
    // These props are injected by FiltersAccordion
    // They cannot be initially required but they are always provided
    // setFilterModalIsActive: PropTypes.func,
    icon: PropTypes.string,
    iconHelp: commonPropTypes.i18nText,
    type: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };
  TagSetFieldFilter.defaultProps = {
    itemId: null,
    valueId: null,
    feedbackFormValuesFieldName: null,
    // setFilterModalIsActive: null,
    // Icon to be displayed close to the filter title
    icon: null,
    // Icon help text displayed on hover
    iconHelp: null,
    // Type of the field, either 'tag' or 'text'
    type: null,
  };
  return TagSetFieldFilter;
};

export default tagSetFieldFilterFactory;
