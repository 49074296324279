

import PropTypes from 'prop-types';

import { BasicLink, LinkText } from 'components/ui/Link';
import { treemapItemPropTypes } from 'components/ui/visualization/Treemap';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const getHierarchichalPath = (nodeId, data) => {
  if (!nodeId) return [{ conceptId: null, parent_id: null, label: 'Global' }];
  const idToInfos = data.find((x) => x.id === nodeId);
  return [idToInfos, ...getHierarchichalPath(idToInfos.parent_id, data)];
};

const conditionallyAddTailElement = (elements, tailElement) => {
  if (!tailElement) return elements;
  return [...elements, tailElement];
};

function Breadcrumbs({
  path,
  onClick,
  inactive,
  fontSize,
  compact,
  style,
  tailElement,
}) {
  const LinkComponent = inactive ? LinkText : BasicLink;
  const finalFontSize = fontSize || svars.fontSizeBase;
  return (
    <div
      style={{
        fontSize: finalFontSize,
        padding: svars.spaceSmall,
        minHeight: compact ? null : svars.treemapBreadcrumbsMinHeight,
        ...style,
      }}
    >
      {conditionallyAddTailElement(
        path
          .slice(0)
          .reverse()
          .map(({ component, id, label }, ind) => {
            const commonProps = {
              key: `breadcrumb-${id}`,
              style: {
                fontSize: finalFontSize,
                padding: `0 ${svars.spaceNormal}`,
              },
            };
            const actionProps = {};
            if (onClick) actionProps.onClick = () => onClick(id);

            return (
              component ||
              ((!actionProps.onClick || path.length === ind + 1) && (
                <LinkText bold={path.length === ind + 1} {...commonProps}>
                  {label}
                </LinkText>
              )) || (
                <LinkComponent {...commonProps} {...actionProps}>
                  {label}
                </LinkComponent>
              )
            );
          }),
        tailElement
      ).reduce(
        (acc, item) => (acc.length > 0 ? [...acc, ' > ', item] : [item]),
        []
      )}
    </div>
  );
}

Breadcrumbs.propTypes = {
  onClick: PropTypes.func,
  path: PropTypes.arrayOf(treemapItemPropTypes).isRequired,
  inactive: PropTypes.bool,
  fontSize: PropTypes.string,
  // Whether display should be compact
  compact: PropTypes.bool,
  // Extra style to apply to container
  style: commonPropTypes.style,
  // Extra breadcrumbs element to be added at the end of the breadcrumb path (usually used for navigation elements)
  tailElement: PropTypes.element,
};

Breadcrumbs.defaultProps = {
  inactive: false,
  onClick: undefined,
  fontSize: undefined,
  compact: false,
  style: {},
  tailElement: null,
};

Breadcrumbs.getHierarchichalPath = getHierarchichalPath;

export default Breadcrumbs;
