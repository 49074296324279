import React from 'react';

import PropTypes from 'prop-types';

class LazyLoadedContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.updateMountedState();
  }

  componentDidUpdate() {
    this.updateMountedState();
  }

  updateMountedState = () => {
    const { hidden } = this.props;
    const { isMounted } = this.state;
    if (!hidden && !isMounted) this.setState({ isMounted: true });
  };

  render() {
    const { children } = this.props;
    const { isMounted } = this.state;
    return isMounted ? children : null;
  }
}

LazyLoadedContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default LazyLoadedContainer;
