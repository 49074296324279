import { shallowEqual, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { paginationSelector as analysisPaginationSelector } from 'selectors/search';
import { paginationSelector as monitoringPaginationSelector } from 'store/monitor/monitorSearchSlice';

import { ButtonSecondaryAccent } from 'components/ui/button';

import { numberFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

function searchPaginationFactory(paginationSelector) {
  function SearchPagination({
    nResults,
    onPageChange,
    loading,
    moreResultsMessage,
    nMaxChunks,
    children,
  }) {
    const { page, pageSize, nPages } = useSelector(
      paginationSelector,
      shallowEqual
    );
    const nDisplayed = pageSize * page < nResults ? pageSize * page : nResults;
    const nTotal = pageSize * nPages < nResults ? pageSize * nPages : nResults;
    const nMaxChunksReached = (nTotal && nMaxChunks === nTotal) || false;
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            paddingTop: svars.spaceMedium,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ButtonSecondaryAccent
              onClick={onPageChange}
              disabled={nDisplayed >= nTotal || loading}
            >
              {moreResultsMessage}
            </ButtonSecondaryAccent>
            {`${nDisplayed ? numberFormatter(nDisplayed) : '-'} / ${
              nTotal
                ? `${numberFormatter(nTotal)}${nMaxChunksReached ? '+' : ''}`
                : '-'
            } ${t({ id: 'extracts' })}`}
          </div>
        </div>
        {children ? <span>{children}</span> : null}
      </div>
    );
  }

  SearchPagination.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    nResults: PropTypes.number,
    loading: PropTypes.bool,
    moreResultsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
      .isRequired,
    nMaxChunks: PropTypes.number,
    children: PropTypes.node,
  };

  SearchPagination.defaultProps = {
    nResults: undefined,
    loading: false,
    nMaxChunks: null,
    children: null,
  };
  return SearchPagination;
}

export const AnalysisSearchPagination = searchPaginationFactory(
  analysisPaginationSelector
);
export const MonitoringSearchPagination = searchPaginationFactory(
  monitoringPaginationSelector
);
