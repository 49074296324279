import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const BodyText = styled.span`
  display: flex;
  flex-direction: column;
  margin: ${svars.spaceNormalLarge} 0 ${svars.spaceNormalLarge} 0;
  font-size: ${({ largeFont: islargefont }) =>
    islargefont ? svars.fontSizeMedium : svars.fontSizeBase};
  color: ${({ isRead: isread }) =>
    isread ? svars.colorGrey : svars.colorBlack};
`;

const ChipContainer = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
`;

const RoundedChip = styled(Label)`
  &&&& {
    border-radius: ${svars.roundedBorderRadius};
    border: ${({ borderColor }) =>
      borderColor ? `1px solid ${borderColor}` : null};
    background-color: ${({ bgColor }) => bgColor};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50%;
  }
`;

function GenericUserNotification({ isRead, preset, type }) {
  let secondaryItem;
  let color;
  let borderColor;
  let iconName;

  if (type === 'PresetUserNotification') {
    secondaryItem = preset.view_facet?.name;
    color = svars.analysisColor;
    borderColor = svars.analysisColorBorder;
    iconName = svars.analysisIcon;
  } else if (type === 'CampaignPresetUserNotification') {
    secondaryItem = preset.campaign?.name;
    color = svars.campaignColor;
    borderColor = svars.campaignColorBorder;
    iconName = svars.campaignIcon;
  }

  return (
    <BodyText isRead={isRead}>
      <ChipContainer>
        <RoundedChip bgColor={svars.colorGreyMediumLight}>
          <Icon name="bookmark" />
          {preset.name}
        </RoundedChip>
        <RoundedChip bgColor={color} borderColor={borderColor}>
          <Icon name={iconName} />
          {secondaryItem}
        </RoundedChip>
      </ChipContainer>
    </BodyText>
  );
}

GenericUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['analysis', 'campaign']).isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    view_facet: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    campaign: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

const bodyComponents = {
  PresetUserNotification: GenericUserNotification,
  CampaignPresetUserNotification: GenericUserNotification,
};

function NotificationBody({ type, isRead, ...otherProps }) {
  const BodyComponent = bodyComponents[type];
  return <BodyComponent isRead={isRead} type={type} {...otherProps} />;
}

NotificationBody.propTypes = {
  type: PropTypes.oneOf([
    'PresetUserNotification',
    'CampaignPresetUserNotification',
  ]).isRequired,
  details: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      campaignName: PropTypes.string,
      source: PropTypes.string,
      analysisName: PropTypes.string,
      annotationField: PropTypes.string,
      oldValue: PropTypes.string,
      newValue: PropTypes.string,
      preset: PropTypes.shape({ name: PropTypes.string }),
    }),
  ]).isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationBody;
