import { useEffect, useState } from 'react';

import { Trans, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

const CenteredContainer = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function EmptyDataVisualization({ message, height }) {
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIcon(true);
    }, 600);

    return () => clearTimeout(timer); // This will clear the timer if the component unmounts before the timer fires
  }, []);
  return (
    <CenteredContainer data-testid="insufficient-data-chart" height={height}>
      {showIcon && (
        <>
          <Icon
            style={{
              fontSize: styleVariables.largeIconFontSize,
              color: styleVariables.fontColorDisabled,
            }}
            name="chart bar outline"
          />
          <div
            style={{
              fontSize: styleVariables.fontSizeSmaller,
              maxWidth: '85%',
            }}
          >
            {(message && ['string', 'object'].includes(typeof message) && (
              <Trans id={message} />
            )) ||
              message}
          </div>
        </>
      )}
    </CenteredContainer>
  );
}
EmptyDataVisualization.propTypes = {
  message: PropTypes.oneOfType([
    commonPropTypes.i18nText,
    PropTypes.string,
    PropTypes.node,
  ]),
  height: PropTypes.number,
};
EmptyDataVisualization.defaultProps = {
  message: msg({ id: 'not-enough-data' }),
  height: null,
};

export default EmptyDataVisualization;
