import styled from 'styled-components';

import * as svars from 'assets/style/variables';
import {
  compactPageMaxWidth,
  pageMaxWidth,
  scrollBarWidth,
  simplePageMaxWidth,
} from 'assets/style/variables';

export const PageLayout = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    flex-grow: 1;
    max-width: ${({ fullWidth, compact, simple }) =>
      (simple && simplePageMaxWidth) ||
      (compact && compactPageMaxWidth) ||
      (fullWidth && '100%') ||
      pageMaxWidth};
    ${({ padded }) =>
      padded &&
      `padding-right: ${scrollBarWidth}; padding: ${svars.spaceMedium};`}
    transition: visibility 0s, opacity 0.5s linear;
  }
`;

export const ButtonLineLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  ${({ padded }) =>
    padded && `padding: ${svars.spaceMedium} ${svars.spaceNormalLarge};`}
`;
